$(document).ready(function(){
    'use strict';

    function init(){
        $('select.select-control').each(function(){
            var w = $(this).data('width');
            if(w == undefined){
                w = '100%';
            }
            $(this).selectpicker({
                size: 4,
                width: w,
            })
        })
        // $('select.select-control').selectpicker({
        //     size: 4,
        //     width: '100%'
        // });

        $('[data-toggle="tooltip"]').tooltip();

        jQuery('img.svg').each(function(i) {
            var $img = jQuery(this);
            var imgID = $img.attr('id');
            var imgClass = $img.attr('class');
            var imgURL = $img.attr('src');

            jQuery.get(imgURL, function(data) {
                // Get the SVG tag, ignore the rest
                var $svg = jQuery(data).find('svg');

                // Add replaced image's ID to the new SVG
                if (typeof imgID !== 'undefined') {
                    $svg = $svg.attr('id', imgID);
                }
                // Add replaced image's classes to the new SVG
                if (typeof imgClass !== 'undefined') {
                    $svg = $svg.attr('class', imgClass + ' replaced-svg');
                }

                // Remove any invalid XML tags as per http://validator.w3.org
                $svg = $svg.removeAttr('xmlns:a');

                // Replace image with new SVG
                $img.replaceWith($svg);

            }, 'xml');

        });
        setTimeout(func, 500);
    }init();

    function inputScale() {
        $.fn.textWidth = function(text, font) {
            if (!$.fn.textWidth.fakeEl) $.fn.textWidth.fakeEl = $('<span>').hide().appendTo(document.body);
            $.fn.textWidth.fakeEl.text(text || this.val() || this.text() || this.attr('placeholder')).css('font', font || this.css('font'));
            return $.fn.textWidth.fakeEl.width();
        };

        $('.width-dynamic').on('input', function() {
            var inputWidth = $(this).textWidth() + 30;
            $(this).css({
                width: inputWidth
            })
        }).trigger('input');

        function inputWidth(elem, minW, maxW) {
            elem = $(this);
            console.log(elem)
        }

        var targetElem = $('.width-dynamic');
        inputWidth(targetElem);
    }

    function func() {

        // other wrap
        $('.has-other').each(function(){
            var t = $(this),
                s = t.find('select'),
                so = s.find('option'),
                sv = s.val(),
                o = t.find('.other-wrap'),
                l = t.find('.list-selected'),
                ll= l.find('ul');

            o.hide();
            l.hide();

            if(sv != ""){
                o.hide();
                l.show();
                var it = ""+sv+"";
                var ti = it.split(',');
                var tso = s.find('option[value='+ ti[i] +']');
                var i,text;
                for (i = 0; i < ti.length; i++) {
                    var textval = t.find('.select-control option[value='+ ti[i] +']').text();
                    // var textval = $(tso).text();
                    text += "<li data-v="+ti[i]+">"+ textval + " <a class='cross-btn'></a></li>";
                    // text += "<li>"+ ti[i] + "</li>";
                    var rte = text.replace('undefined','');

                }
                ll.html(rte);
            }

            s.change(function(){
                var v = $(this).val();
                if(v == 'other'){
                    o.show();
                }else if(jQuery.inArray("other", v ) != -1){
                    l.hide();
                    o.show();
                    $(this).val([]);
                    $(this).val('default');
                    $(this).selectpicker('val', "other")
                    $(this).selectpicker("refresh");
                }else if(v == ''){
                    l.hide();
                    o.hide();
                }else{
                    o.hide();
                    l.show();
                    var it = ""+v+"";
                    var ti = it.split(',');
                    var tso = s.find('option[value='+ ti[i] +']');
                    var i,text;
                    for (i = 0; i < ti.length; i++) {
                        var textval = t.find('.select-control option[value='+ ti[i] +']').text();
                        // var textval = $(tso).text();
                        text += "<li data-v="+ti[i]+">"+ textval + " <a class='cross-btn'></a></li>";
                        // text += "<li>"+ ti[i] + "</li>";
                        var rte = text.replace('undefined','');

                    }
                    ll.html(rte);
                }
            });

            // var sv = s.val();
            // if(s.val() != ""){
            //     o.hide();
            //     l.show();
            //     // var it = ""+sv+"";
            //     var ti = it.split(',');
            //     var tso = s.find('option[value='+ ti[i] +']');
            //     var i,text;
            //     for (i = 0; i < ti.length; i++) {
            //         var textval = t.find('.select-control option[value='+ ti[i] +']').text();
            //         // var textval = $(tso).text();
            //         text += "<li data-v="+ti[i]+">"+ textval + "</li>";
            //         // text += "<li>"+ ti[i] + "</li>";
            //         var rte = text.replace('undefined','');

            //     }
            //     ll.html(rte);
            // }
        });

        $('.list-selected').each(function(){
            var t = $(this),
                csc = t.siblings('.bootstrap-select'),
                cs = t.siblings('.select-control'),
                drm = $('body').find('ul.dropdown-menu'),
                drmi = drm.find('div.inner'),
                drmii = csc.find('ul.dropdown-menu'),
                drms = drmi.closest('.selected');

            t.on('click', '.cross-btn', function(){
                var tp = $(this).parent(),
                    i  = tp.index()+1,
                    dv = tp.data('v'),
                    sco = csc.find('[value='+dv+']'),
                    sct = csc.find('option');
                sct.each(function(){
                    var t = $(this);
                    // console.log(t.text());
                    // console.log(tp.text());
                    if(tp.text() == t.text()){
                        // $(this).prop('selected', false);
                        alert('aaa')
                    }
                })
                sco.prop('selected', false);
                tp.remove();
                $('select.select-control').selectpicker('refresh');
                // console.log(tp.data('v'));
                // console.log(sct)
            });
        });

        // event date
        $('.has-date').each(function(){
            var t = $(this),
                s = t.find('select'),
                o = t.find('.date-wrap');

            o.hide();

            s.change(function(){
                var v = $(this).val();
                if(v !== ''){
                    o.show();
                }else{
                    o.hide();
                }
            })
        })

        $('.has-project').each(function(){
            var t = $(this),
                s = t.find('select.hp'),
                o = t.find('.project-wrap'),
                y = $(this).find(':selected').attr('data-trigger');;

            if(y == '1'){
                o.show();
                o.find('select').attr("disabled", false);
            } else {
                o.hide();
                o.find('select').attr("disabled", true);
            }

            s.change(function(){
                var x = $(this).find(':selected').attr('data-trigger');
                if(x == '1'){
                    o.show();
                    o.find('select').attr("disabled", false);
                } else {
                    o.hide();
                    o.find('select').attr("disabled", true);
                }
            })
        })

        //input number
        $("body").find('input.number').keydown(function(e) {
            // console.log(e.keyCode);
            if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
                (e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true)) ||
                (e.keyCode >= 35 && e.keyCode <= 40)) {
                return;
            }
            if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
                e.preventDefault();
            }
        });
        $("body").find('input.phone').keydown(function(e) {
            // console.log(e.keyCode);
            if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 187, 188, 189, 190]) !== -1 ||
                (e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true)) ||
                (e.keyCode >= 35 && e.keyCode <= 40)) {
                return;
            }
            if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
                e.preventDefault();
            }
        });

        $('.title-editable').each(function(){
            var t = $(this),
                tt = t.find('.title .editable'),
                ie = t.find('.input-edit'),
                be = t.find('.btn-edit'),
                bs = t.find('.btn-save'),
                bc = t.find('.btn-cancel');

            ie.val(tt.text());
            inputScale();

            be.click(function(){ t.addClass('editing') });
            bc.click(function(){ t.removeClass('editing') });
            bs.click(function() {
                var isi = ie.val();
                tt.text(isi);
                t.removeClass('editing');
            });
        })

        $('.comment-editable').each(function(){
            var t = $(this),
                text = t.find('.text'),
                cb = t.find('.comment-box'),
                be = t.find('.btn-edit'),
                bs = t.find('.btn-save'),
                bc = t.find('.btn-cancel');

            be.click(function(e){ e.preventDefault(); t.addClass('editing') });
            bc.click(function(e){ e.preventDefault(); t.removeClass('editing') });
            // bs.click(function(){ t.removeClass('editing') });
        })

        $('.reminder-editable').each(function(){
            var t = $(this),
                text = t.find('.text'),
                cb = t.find('.comment-box'),
                be = t.find('.btn-edit'),
                bs = t.find('.btn-save'),
                bc = t.find('.btn-cancel');

            be.click(function(e){ e.preventDefault(); t.addClass('editing') });
            bc.click(function(e){ e.preventDefault(); t.removeClass('editing') });
            // bs.click(function(){  t.removeClass('editing') });
        })

        // DATEPICKER
        $('.datepicker').each(function(){
            var t = $(this);
            t.find('input').datepicker({
                format: 'dd M yyyy',
                // format : "dd/mm/yyyy",
                todayHighlight: true,
                autoclose: true
            });
        });

        // UPLOAD IMAGE
        var inputimgs = $('.file-control.file-images');
        inputimgs.each(function(){
            var t = $(this),
                input = t.find('input[type="file"]'),
                max = input.data('max'),
                preview = t.find('.preview-images'),
                btnDel = t.find('.file-delete');

            input.change(function(){
                if(this.files && this.files[0]) {
                    var fl = (this.files.length);
                    if(fl <= max){
                        preview.find('.thumb').remove();
                        $(this.files).each(function() {
                            var reader = new FileReader();
                            reader.readAsDataURL(this);
                            reader.onload = function(e) {
                                preview.append('<div class="thumb"><div class="img"><img src="'+ e.target.result +'"></div></div>');
                            }
                        })
                        t.addClass('has-file');
                        if(fl == max){
                            t.addClass('disabled');
                        }
                    }else{
                        alert('To many files selected')
                    }
                }

                // get inputfile multiple values
                // var names = [];
                // for (var i = 0; i < $(this).get(0).files.length; ++i) {
                //     names.push($(this).get(0).files[i].name);
                // }
                // console.log(names);
            })

            btnDel.click(function(){
                input.val('');
                preview.find('.thumb').remove();
                t.removeClass('has-file');
                t.removeClass('disabled');
            })
        })


        // More filters
        $('.page-filter').each(function(){
            var t = $(this),
                bMore = t.find('.btn-more'),
                wMore = t.find('.more-filters');

            bMore.click(function(){
                t.toggleClass('show-more');
                if(t.hasClass('show-more')){
                    $(this).html('Less filters');
                } else{
                    $(this).text('More filters');
                }
            })
        })


        $('.data-country-code').each(function(){
            var t = $(this),
                target = t.data('field');

            t.change(function(){
                var option = t.find("option:selected").attr('data-code');
                console.log(option);

                $('.'+target).each(function(){
                    $(this).val(option);
                })
            })
        })

        $("#startDate").each(function(){
            $(this).change(function(){
                var start = $(this).val();

                $('#endDate').datepicker('destroy').val('');
                $('#endDate').datepicker({
                    format: 'dd M yyyy',
                    todayHighlight: true,
                    autoclose: true,
                    startDate: start,
                });
            });
        });

        // FIELDS MULTIPLIER
        $('.multiplier').each(function(){
            var t       = $(this),
                max     = t.data('max'),
                box     = t.find('.box-item'),
                item    = t.find('.item').eq(0),
                add     = t.find('.btn-add'),
                format;

            item.find('select').each(function(){
            $(this).selectpicker('destroy');
            });

            format = item.clone().hide();
            bind(item);

            function bind (obj) {
            setPlugin();
            }

            function reOrder(obj){
            var itm = obj.find('.item');
            itm.each(function(i){
                var tmp = i + 1;
                //re order name
                $(this).find('input').each(function(){
                if($(this).attr('name')){
                    var name = $(this).attr('name').replace( /\[\d/g,'['+(i));
                    $(this).attr('name',name);
                }
                });
                //Re order title
                $(this).find('.title').each(function(){
                var title = $(this).text();
                title = title.substring(0, title.indexOf(' '));
                $(this).text('Drug ' + (i+1));
                })

            });

            //toggle delete button
            if(itm.length > 1){
                itm.closest('.multiplier').addClass('multiplied');
            }else{
                itm.closest('.multiplier').removeClass('multiplied');
            }
            //cek limit
            if(itm.length >= max){
                itm.closest('.multiplier').addClass('max');
            }else{
                itm.closest('.multiplier').removeClass('max');
            };
            };

            add.click(function(e){
            e.preventDefault();
            var html = format.clone();
            box.append(html);
            bind(html);
            html.slideDown(300);
            reOrder(box);
            });

            // DELETE
            $('body').on('click','.btn-del',function(e){
            e.preventDefault();
            var parent = $(this).closest('.item'),
                target = parent.closest('.multiplier');

            parent.slideUp(300, function(){
                parent.remove();
                reOrder(target);
            });
            });
        });


    }


});